<script>
import StudentResponsiblesService from '@/service/student-responsibles.service';
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'modal-register-student-responsible',

  props: {
    studentId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      form: { address: {} },
      masked: {
        cpf: '',
        phone: '',
        address: {
          cep: '',
        },
      },
    };
  },

  created() {
    this.$on('create', this.create);
    this.$on('edit', this.edit);
  },

  methods: {
    create() {
      this.clearForm();
      this.dialog = true;
    },

    onMaska(val, field) {
      set(this.form, field, val);
    },

    edit(id) {
      this.fetch(id);
      this.dialog = true;
    },

    async fetch(id) {
      this.loading = true;

      try {
        const studentResponsible = await StudentResponsiblesService.get(id);
        this.form = cloneDeep(studentResponsible);
        if (studentResponsible.address) {
          this.masked.address = cloneDeep(studentResponsible.address);
        } else {
          this.form.address = {};
        }
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async save() {
      this.loadingSave = true;
      try {
        const form = cloneDeep(this.form);
        if (form.id) {
          const id = this.form.id;
          delete form.id;
          await StudentResponsiblesService.update(id, form);
        } else {
          await StudentResponsiblesService.create(form);
        }
        this.dialog = false;
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Salvo com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
        this.$emit('success');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },

    async findAddressByCep(event) {
      if (event.target.value) {
        const res = await axios.get(`https://brasilapi.com.br/api/cep/v2/${event.target.value}`);
        this.form.address = res?.data;
      } else {
        this.form.address = {};
      }
    },

    clearForm() {
      this.form = { address: {}, customer: {}, studentId: this.studentId };
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="1400px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ !form.id ? 'Cadastro' : 'Edição' }} de responsável</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="8" sm="8">
                  <v-row>
                    <v-col v-if="form.id" sm="2" lg="2">
                      <v-text-field
                        v-model="form.id"
                        label="ID"
                        color="white"
                        hide-details="auto"
                        disabled
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col sm="10" lg="3">
                      <v-text-field
                        v-model="form.cpf"
                        v-mask="'###.###.###-##'"
                        :value-raw="masked.cpf"
                        :value="masked.cpf"
                        label="CPF"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                        @rawValue="onMaska($event, 'cpf')"
                      />
                    </v-col>
                    <v-col sm="12" lg="7">
                      <v-text-field
                        v-model="form.name"
                        label="Nome completo"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <v-text-field
                        v-model="form.birthDate"
                        label="Data de Nascimento"
                        color="white"
                        hide-details="auto"
                        type="date"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="form.kinship"
                        label="Parentesco"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.phone"
                        v-mask="'(##) #####-####'"
                        :value-raw="masked.phone"
                        :value="masked.phone"
                        label="Celular"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                        @rawValue="onMaska($event, 'phone')"
                      />
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="form.email"
                        label="Email"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-subheader>
                <v-row>
                  <span>Endereço</span>
                </v-row>
              </v-subheader>
              <v-row>
                <v-col cols="9">
                  <v-row>
                    <v-col cols="2">
                      <v-text-field
                        v-model="form.address.cep"
                        v-mask="'#####-###'"
                        :value="masked.address.cep"
                        :value-raw="masked.address.cep"
                        label="CEP"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                        @blur="findAddressByCep"
                        @rawValue="onMaska($event, 'address.cep')"
                      />
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="form.address.street"
                        label="Rua"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="form.address.number"
                        label="Número"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        v-model="form.address.neighborhood"
                        label="Bairro"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="form.address.city"
                        label="Cidade"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="form.address.state"
                        label="UF"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="form.annotation"
                        label="Anotações"
                        color="white"
                        hide-details="auto"
                        auto-grow
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
